import React, { useEffect } from 'react';
import HeroSection from './HeroSection/HeroSection';
import ContactBar from './ContactBar/ContactBar';
import { BestSellers } from './BestSellers/BestSellers';
import Reviews from './Reviews/Reviews';

interface HomePageProps {
  config: any;
  setProductModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function HomePage({ config, setProductModalOpen }: HomePageProps) {

  return (
    <div className="HomePage" style={{width: '100%'}}>
      {config['hero-section'] && <HeroSection config={config['hero-section']} />}
      {config['contact-bar'] && <ContactBar config={config['contact-bar']} />}
      {config['best-sellers'] && (
        <BestSellers 
          config={config['best-sellers']} 
          setProductModalOpen={setProductModalOpen}
        />
      )}
      {config['reviews-section'] && <Reviews config={config['reviews-section']} />}
    </div>
  );
}

export default HomePage;
