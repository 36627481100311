import React, { useEffect } from 'react';
import './Belen.css'

function BelenShopPage({config} : {
    config: any
}) {
    console.log("shop page")
    useEffect(() => {
        // @ts-ignore
        const sdk = new window.ProductSDK({
        baseUrl: 'https://api.gobrandica.com',
        storeId: 'f2ab8e8a-0abe-4392-9e75-af25393ef484',
        locationTag: 'BelenStore',
        darkMode: true,
        transparent: true
        });
    
        sdk.init('product-container').displayProducts(true);
    }, [])

  return (
    <div className="BelenShopPage">
        <div id="product-container"></div>
        <div className="shop-page-background">
            <div  style={{
                backgroundImage: `url(${config.backgroundImage})`,
                // tile background image
                backgroundSize: '200px 200px',
                width: '100%',
                height: '100%',

            }}></div>
        </div>
    </div>
  );
}

export default BelenShopPage;
