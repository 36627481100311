import React, { useEffect, useState } from 'react';
import './App.css';
import Navbar from './components/Navbar';
import { Route, Routes, useLocation } from 'react-router-dom';
import HomePage from './components/Home/Home';
import { Footer } from './components/Footer';
import Loading from './components/Loading';
import AboutPage from './components/About/About';
import { AgeVerification } from './components/AgeVerification';
import ContactModal from './components/ContactModal';
import { Fab } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import ErrorBoundary from './components/ErrorBoundary';
import { monitoring } from './services/monitoring';
import BelenShopPage from './components/Shop/Belen';

function App() {
  const [loading, setLoading] = useState(true);
  const [homeConfig, setHomeConfig] = useState<any>({});
  const [aboutConfig, setAboutConfig] = useState({});
  const location = useLocation();

  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [ageVerificationOpen, setAgeVerificationOpen] = useState(false);
  const [productModalOpen, setProductModalOpen] = useState(false);

  const fabStyle = {
    position: 'fixed',
    bottom: 16,
    right: 16,
    backgroundColor: '#146C43',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#146C43',
      color: '#fff'
    }
  };

  const closeAllModals = () => {
    setContactModalOpen(false);
    setAgeVerificationOpen(false);
    setProductModalOpen(false);
  };

  const toggleContactModal = () => {
    closeAllModals();
    setContactModalOpen(!contactModalOpen);
  };

  useEffect(() => {
    setLoading(true);
    if(location.pathname === '/' || location.pathname === '/shop/belen') {
      fetchConfig('home-config.json').then((data) => {
        setHomeConfig(data);
        setLoading(false);
      });
    }

    if(location.pathname === '/about') {
      fetchConfig('about-config.json').then((data) => {
        setAboutConfig(data);
        setLoading(false);
      });
    }
  }, [location.pathname]);

  const fetchConfig = async (url: string) => {
    return await (await fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    })).json();
  }

  const handleAgeVerificationClose = () => {
    setAgeVerificationOpen(false);
    // You might want to add additional logic here if needed
  };

  console.log({homeConfig})

  return (
    <div className="App">
      {
        loading ? <Loading/> : <>
          {location.pathname.includes('/shop/belen') ? null : <Navbar /> }
          <AgeVerification isOpen={ageVerificationOpen} onClose={handleAgeVerificationClose} />
          <ContactModal isOpen={contactModalOpen} toggleOpen={toggleContactModal}/>
           {location.pathname.includes('/shop/belen') ? null : <Fab sx={fabStyle} className="contact-us-fab" onClick={toggleContactModal}>
            <FontAwesomeIcon icon={faPhone} />
          </Fab> }
          <Routes>
            <Route path="/" element={<HomePage config={homeConfig} setProductModalOpen={setProductModalOpen} />} />
            <Route path="/about" element={<AboutPage config={aboutConfig}/>} />
            <Route path="/shop/belen" element={<BelenShopPage config={homeConfig['best-sellers']} />} />
          </Routes>
          <ErrorBoundary 
            fallback={<div className="error-container">Footer temporarily unavailable</div>}
          >
          {location.pathname.includes('/shop/belen') ? null : <Footer /> }
          </ErrorBoundary>
        </>
      }
    </div>
  );
}

export default App;
